<form #informationsForm="ngForm">
    <div *ngIf="foAccount"
         class="wrapper">
        <div class="has-space" [ngClass]="{'register-block' : isRegister, 'space-bottom-32' : !isRegister}">
            <h3 class="grid-x align-middle secondary block-title">
                {{'informations.administrator.title' | translate}}
            </h3>

            <div class="cell auto">&nbsp;</div>

            <div class="grid-x">
                <div class="grid-y cell small-24 medium-12">
                    <div class="register-form-part business">
                        <div class="grid-x grid-padding-x">
                            <label class="cell small-24 xxlarge-12 margin-label bottom required"
                                   [ngClass]="{validate : !!companyName.valid}">
                                <span class="labelName">{{'informations.administrator.companyName' | translate}}</span>
                                <input type="text" [(ngModel)]="foAccount.companyName" name="companyName"
                                       #companyName="ngModel"
                                       [required]="true">
                            </label>
                            <siret-input class="cell small-24 xxlarge-12 grid-y margin-label bottom"
                                         [(ngModel)]="foAccount.companySiret"
                                         name="siret"
                                         [required]="siretMandatory">
                            </siret-input>
                        </div>

                        <div class="grid-x grid-padding-x">
                            <label class="cell small-24 xxlarge-12 margin-label bottom optional">
                                <span class="labelName">{{'informations.administrator.siteName' | translate}}</span>
                                <input type="text" [(ngModel)]="foAccount.siteName" name="siteName">
                            </label>
                        </div>

                        <ng-container *ngIf="user.stationManager">
                            <div class="grid-x grid-padding-x">
                                <label class="cell small-24 xxlarge-18 margin-label bottom">
                                    <div>
                                        {{'informations.companyInfo.logo' | translate}}
                                        <i class="fas fa-question-circle"
                                           [title]="'informations.companyInfo.logoSize' | translate"></i>
                                    </div>
                                    <input-file *ngIf="!foAccount.logoRef"
                                                [(ngModel)]="logo"
                                                (ngModelChange)="uploadFile($event)"
                                                [text]="''"
                                                name="logo">
                                    </input-file>

                                    <img *ngIf="foAccount.logoRef"
                                         [src]="'/api/download/' + foAccount.logoRef"
                                         style="height: 100px; max-width: 300px;">
                                </label>
                                <button *ngIf="foAccount.logoRef"
                                        (click)="removeLogo()"
                                        type="button"
                                        class="button round secondary titleDisabledButton"
                                        style="margin-top: 100px;">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>

                            <div class="grid-x grid-padding-x margin-label bottom">
                                <div class="cell small-12">
                                    <label class="required"
                                           [ngClass]="{validate : !!mainActivityCode.valid, invalid : !mainActivityCode.valid && mainActivityCode?.dirty}">
                                        <span class="labelName">
                                            {{'informations.companyInfo.mainActivityCode.label' | translate}}
                                            <i class="fas fa-question-circle"
                                               style="margin-left: 4px;"
                                               [title]="'informations.companyInfo.mainActivityCode.explanation' |
                                               translate"></i>
                                        </span>
                                        <input #mainActivityCode="ngModel"
                                               [(ngModel)]="foAccount.mainActivityCode"
                                               [maxLength]="5"
                                               name="mainActivityCode"
                                               pattern="[0-9]{4}[a-zA-Z]{1}"
                                               required
                                               type="text">
                                        <div class="cell note error"
                                             [ngClass]="{'hiddenError': !(mainActivityCode.invalid
                                             && (mainActivityCode?.touched || mainActivityCode?.dirty))}">
                                            {{'informations.companyInfo.mainActivityCode.error' | translate}}
                                        </div>
                                    </label>
                                </div>
                                <label class="cell small-12 required"
                                       [ngClass]="{validate : !!vatNumber.valid}">
                                    <span class="labelName">{{'informations.companyInfo.vatCode' | translate}}</span>
                                    <input type="text"
                                           [(ngModel)]="foAccount.vatCode"
                                           [required]="true"
                                           #vatNumber="ngModel"
                                           name="vatCode">
                                </label>
                            </div>
                        </ng-container>

                        <div class="grid-x grid-padding-x"
                             *ngIf="declarableAsCollectivity">
                            <label class="cell small-24 xxlarge-12 margin-label bottom checkbox">
                                <input type="checkbox"
                                       name="collectivity"
                                       #collectivity="ngModel"
                                       [(ngModel)]="foAccount.collectivity"
                                       [disabled]="!isRegister">
                                <span class="custom-checkbox"></span>
                                <span
                                    class="label-choice checkbox-margin ">{{'informations.administrator.chorusProConfiguration.collectivity' | translate}}</span>
                            </label>
                        </div>
                        <div class="grid-x grid-padding-x" *ngIf="foAccount.collectivity">
                            <label class="cell small-24 xxlarge-12 margin-label bottom">
                                <span class="labelName">{{'informations.administrator.chorusProConfiguration.serviceNumber' |
                                    translate}}</span>
                                <input [disabled]="!isRegister && hasUnpaidInvoice"
                                       type="text"
                                       [(ngModel)]="foAccount.chorusProConfiguration.serviceNumber"
                                       #serviceNumber="ngModel"
                                       name="serviceNumber">
                            </label>
                        </div>

                        <address-form-wrapper [address]="user.address"
                                                 [required]="true"
                                                 (formAddressValidChange)="updateIsValidUserAddress($event)">
                        </address-form-wrapper>
                    </div>
                </div>
                <div class="grid-y cell small-24 medium-12">
                    <div class="register-form-part profile">
                        <div class="grid-x grid-padding-x" *ngIf="foAccount?.foAccountClientRef">
                            <div class="cell small-12">
                                <label class="labelName">
                                    <span>{{'informations.administrator.clientRef' | translate}}</span>
                                    &nbsp;<b>{{foAccount.foAccountClientRef}}</b>
                                </label>
                            </div>
                        </div>

                        <div class="grid-x grid-padding-x margin-label bottom">
                            <div class="cell small-12">
                                <label class="required" [ngClass]="{validate : !!firstName.valid}">
                                    <span class="labelName">{{'informations.administrator.firstName' | translate}}</span>
                                    <input type="text" [ngModel]="user.firstName"
                                           (ngModelChange)="complete($event,'firstName')" name="fistName"
                                           #firstName="ngModel" [required]="true">
                                </label>
                            </div>
                            <label class="cell small-12 required" [ngClass]="{validate : !!lastName.valid}">
                                <span class="labelName">{{'informations.administrator.lastName' | translate}}</span>
                                <input type="text" [ngModel]="user.lastName"
                                       (ngModelChange)="complete($event,'lastName')"
                                       name="lastName" #lastName="ngModel" [required]="true">
                            </label>
                        </div>

                        <div *ngIf="finalClientConfigurable"
                             class="grid-x grid-padding-x margin-label bottom">
                            <div class="cell small-12">
                                <label class="required"
                                       [ngClass]="{validate : !!socialReason.valid}">
                                    <span
                                        class="labelName">{{'informations.administrator.socialReason' | translate}}</span>
                                    <input #socialReason="ngModel"
                                           type="text"
                                           [required]="true"
                                           [(ngModel)]="foAccount.socialReason"
                                           name="socialReason">
                                </label>
                            </div>
                            <div class="cell small-12">
                                <label class="required"
                                       [ngClass]="{validate : !!finalClientRef.valid}">
                                    <span
                                        class="labelName">{{'informations.administrator.finalClientRef' | translate}}</span>
                                    <input #finalClientRef="ngModel"
                                           type="text"
                                           [required]="true"
                                           [(ngModel)]="foAccount.finalClientRef"
                                           name="finalClientRef">
                                </label>
                            </div>
                        </div>

                        <div class="grid-x grid-padding-x">
                            <div class="grid-y cell small-24 xxlarge-12 margin-label bottom" *ngIf="!isRegister">
                                <label class="cell required"
                                       [ngClass]="{validate : !!email?.valid, invalid: !!email?.invalid}">
                                    <span
                                        class="cell shrink labelName">{{'informations.administrator.email.title' | translate}}</span>
                                    <input type="email" iziviaEmailValidator="email"
                                           [ngModel]="user.email" (ngModelChange)=" complete($event,'email')"
                                           name="email"
                                           #email="ngModel" [required]="true">
                                </label>
                                <div class="cell note error"
                                     [ngClass]="{'hiddenError': !(email?.invalid && (email?.touched || email?.dirty))}">
                                    {{'informations.administrator.email.incorrect' | translate}}
                                </div>
                            </div>
                            <div class="grid-y cell small-24 xxlarge-12 margin-label bottom" *ngIf="isRegister">
                                <label class="cell required"
                                       [ngClass]="{validate : !!email?.valid, invalid: email?.invalid && email?.dirty}">
                                    <span
                                        class="cell shrink labelName">{{'informations.administrator.email.title' | translate}}</span>
                                    <input type="email" iziviaEmailValidator="email"
                                           iziviaEmailUniquenessValidatorDirective="email"
                                           [ngModel]="user.email" (ngModelChange)=" complete($event,'email')"
                                           name="email"
                                           #email="ngModel" [required]="true">
                                    <div class="cell note error"
                                         [hidden]="!(email?.invalid && email?.errors.emailAlreadyExists && email?.dirty)"
                                         [ngClass]="{'hiddenError': !(email?.invalid && email?.errors.emailAlreadyExists && email?.dirty)}">
                                        {{'informations.administrator.email.stillExist' | translate}}
                                    </div>
                                    <div class="cell note error"
                                         [hidden]="!(email?.invalid && !email?.errors.emailAlreadyExists && email?.dirty)"
                                         [ngClass]="{'hiddenError': !(email?.invalid && !email?.errors.emailAlreadyExists && email?.dirty)}">
                                        {{'informations.administrator.email.incorrect' | translate}}
                                    </div>
                                </label>
                            </div>
                            <div class="xxlarge-1"></div>
                            <div class="grid-y cell small-24 xxlarge-12 margin-label bottom" *ngIf="!isRegister">
                                <label class="cell">
                                    <span class="cell shrink labelName">{{'informations.administrator.login' | translate}}</span>
                                    <input type="email" [ngModel]="login" name="login" disabled>
                                </label>
                            </div>
                            <div class="grid-y cell small-24 xxlarge-12 margin-label bottom">
                                <label class="grid-y cell required"
                                       [ngClass]="{validate : !!phoneNumber.valid, invalid : !phoneNumber.valid && phoneNumber?.touched}">
                                    <span class="labelName">{{'informations.administrator.phoneNumber.title' | translate}}</span>
                                    <int-phone-number-input
                                        inputId="phoneNumber"
                                        name="phoneNumber"
                                        #phoneNumber="ngModel"
                                        [ngModel]="user.phoneNumber"
                                        (ngModelChange)="complete($event, 'phoneNumber')"
                                        [required]="true"
                                    ></int-phone-number-input>

                                </label>
                            </div>
                        </div>
                        <div class="grid-x grid-padding-x">
                            <button class="cell button color" type="submit" *ngIf="!isRegister"
                                    [hidden]="isPasswordChange"
                                    (click)="changePassword()">{{'informations.administrator.password.change' | translate}}
                            </button>
                        </div>
                        <div class="grid-x grid-padding-x" *ngIf="!(!isRegister && !isPasswordChange)">
                            <div class="grid-y cell small-24 xxlarge-12 margin-label bottom">
                                <label class="cell required"
                                       [ngClass]="{validate : !!pwd.valid, invalid : !pwd.valid && pwd?.dirty}">
                                    <span
                                        class="cell shrink labelName">{{'informations.administrator.password.title' | translate}}</span>
                                    <show-hide-password-input
                                        [(ngModel)]="password"
                                        [required]="!(!isRegister && !isPasswordChange)"
                                        [autocomplete]="'new-password'"
                                        name="pwd"
                                        #pwd='ngModel'
                                    ></show-hide-password-input>

                                </label>
                                <password-validation-errors
                                    class="space-top-8"
                                    [errors]="pwd.control.errors"
                                    [definitions]="passwordValidatorDefinitions"
                                >
                                </password-validation-errors>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <envelope
                [person-address]="user"
                [(ngModel)]="userAddressEnvelopeValidated"
                name="envelopeBilling">
            </envelope>
        </div>

        <div class="has-space" [ngClass]="{'register-block' : isRegister, 'space-bottom-32' : !isRegister}">
            <div class="grid-x align-middle">
                <h3 class="cell auto secondary block-title">
                    {{'informations.billing.title' | translate}}
                </h3>

                <div class="cell shrink">
                    <label class="checkbox">
                        <input type="checkbox" [ngModel]="billingAutoComplete" (ngModelChange)="autoCompleteBillingAddressUsingUserAddress()"
                               name="billingAutoComplete">
                        <span class="custom-checkbox"></span>
                        <span class="label-choice">{{'informations.billing.autoComplete' | translate}}</span>
                    </label>
                </div>
            </div>

            <!--Billing Address-->
            <address-form *ngIf="foAccount"
                          [foAccount]="foAccount"
                          [mode]="billingAutoComplete ? ModeAddressForm.BILLING_ENVELOPE : ModeAddressForm.BILLING"
                          [envelopeValidated]="!isRegister"
                          (isFormAddressValid)="onChangeBillingAddress($event)"
                          (isFormAddressDirty)="onTouchBillingAddress($event)">
            </address-form>
        </div>

        <div class="has-space" [ngClass]="{'register-block' : isRegister, 'space-bottom-32' : !isRegister}">
            <div class="grid-x align-middle">
                <h3 class="cell shrink secondary block-title">
                    {{'informations.delivery.title' | translate}}
                </h3>

                <div class="cell auto">&nbsp;</div>
                <div class="cell shrink">
                    <div class="cell small-24">
                        <label class="checkbox" [ngClass]="{'space-right-32': !billingAutoComplete}">
                            <input type="checkbox" [ngModel]="deliveryAutoCompleteAdministrator"
                                   (ngModelChange)="autoCompleteAdministrator()"
                                   name="deliveryAutoCompleteAdministrator">
                            <span class="custom-checkbox"></span>
                            <span
                                class="label-choice">{{'informations.delivery.autoCompleteAdministrator' | translate}}</span>
                        </label>
                    </div>

                    <div class="cell small-24" [hidden]="billingAutoComplete">
                        <label class="checkbox">
                            <input type="checkbox" [ngModel]="deliveryAutoCompleteBilling"
                                   (ngModelChange)="autoCompleteBilling()" name="deliveryAutoCompleteBilling">
                            <span class="custom-checkbox"></span>
                            <span
                                class="label-choice">{{'informations.delivery.autoCompleteBilling' | translate}}</span>
                        </label>
                    </div>
                </div>
            </div>

            <!--Delivery Address-->
            <address-form *ngIf="foAccount"
                          [foAccount]="foAccount"
                          [mode]="deliveryAutoCompleteAdministrator || deliveryAutoCompleteBilling ? ModeAddressForm.DELIVERY_ENVELOPE : ModeAddressForm.DELIVERY"
                          [envelopeValidated]="!isRegister"
                          (isFormAddressValid)="onChangeDeliveryAddress($event)"
                          (isFormAddressDirty)="onTouchDeliveryAddress($event)">
            </address-form>
        </div>

        <div  class="has-space" *ngIf="!isRegister">
            <div class="grid-y">
                <h3 class="grid-x align-middle secondary block-title">
                    {{'informations.rgpd.title' | translate}}
                </h3>
                <div *ngIf="displayOptInAgreement"
                     class="cell shrink margin-rgpd">
                    <label>
                        <input type="checkbox" name="optIn" [(ngModel)]="user.optIn">
                        <span class="custom-checkbox"></span>
                        <span
                            class="label-choice checkbox-margin ">{{'informations.rgpd.optInAgreement' | translate}}</span>
                    </label>
                </div>
                <div *ngIf="displayAdvertAgreement"
                     class="cell shrink">
                    <label>
                        <input type="checkbox" name="advert" [(ngModel)]="user.advert">
                        <span class="custom-checkbox"></span>
                        <span
                            class="label-choice checkbox-margin ">{{'informations.rgpd.advertAgreement' | translate}}</span>
                    </label>
                </div>
                <div class="cell shrink margin-rgpd">
                    <a *ngIf="expenseReportRgpdDocumentUrl" [href]="expenseReportRgpdDocumentUrl" target="_blank">
                        {{'expenseReport.rgpd.displayDocument' | translate}}
                    </a>
                </div>

                <hr class="space-top-24">

                <div class="rgpdContainer">
                    <span class="rgpdContainer-icon"><i class="far fa-file-alt"></i></span>
                    <a target="_blank"
                       [href]="rgpdFormUrl">
                        {{'informations.rgpd.download' | translate}}
                    </a>
                </div>

                <div class="cell shrink margin-downloadLink" *ngIf="!!termsUrl">
                    <a target="_blank" [href]="termsUrl">{{'informations.terms.download' | translate}}</a>
                </div>
                <div class="cell force-text-justify margin-rgpd"
                     [innerHTML]="'informations.rgpd.htmlText' | translate">
                </div>
            </div>
        </div>

        <div class="text-right" *ngIf="foAccount && user._id">
            <button (click)="displayModalAccordingToClosableState()"
                    [iziviaLoading]="closingAccount"
                    [disabled]="saving"
                    type="button"
                    class="button color next">
                {{'config.account.closeAccount.action' | translate}}
            </button>
        </div>
    </div>

    <div class="text-right saveButton has-space" [ngClass]="{'register-block' : isRegister}">
        <button class="button color next" type="submit" (click)="validate()"
                [iziviaLoading]="saving"
                [disabled]="!informationsForm.valid || !isValidUserAddress || !isValidBillingAddress || !isValidDeliveryAddress || closingAccount">
            {{buttonText}}
        </button>
    </div>
</form>


<izivia-popup #closingAccountStatePopup [size]="'medium'">
    <div *ngIf="closableAccountState" class="iziviaPopup">
        <div class="iziviaPopup-header">
            <i class="fas fa-exclamation iziviaPopup-header_icon"></i>
            <h3 class="iziviaPopup-header_title">{{'config.account.closeAccount.closingStateInformation.message' | translate}}</h3>
        </div>
        <div class="iziviaPopup-content">
            <div class="cell" *ngIf="closableAccountState.unpaidInvoices">
                <p>
                    {{'config.account.closeAccount.closingStateInformation.unpaidInvoices.text' | translate}}
                    <a [routerLink]="['/main/invoices']">
                        {{'config.account.closeAccount.closingStateInformation.unpaidInvoices.link' | translate}}
                    </a>
                </p>
            </div>
            <div class="cell" *ngIf="closableAccountState.blockingSubscriptions">
                <p>
                    {{'config.account.closeAccount.closingStateInformation.blockingSubscriptions.text' | translate}}
                    <a [routerLink]="['/main/subscriptions']">
                        {{'config.account.closeAccount.closingStateInformation.blockingSubscriptions.link' | translate}}
                    </a>
                </p>
            </div>
        </div>

        <div class="iziviaPopup-buttons">
            <button type="button"
                    (click)="closingAccountStatePopup.close()"
                    class="button color">
                {{ 'common.close' | translate }}
            </button>
        </div>
    </div>
</izivia-popup>

