import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {
    CollaboratorSearchCriteria,
    FleetImportRequestReportProcessing,
    FleetUser,
    FleetUserRequest,
    FrontAccountHttpService,
    FrontEndFleetConfig,
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontPersonHttpService,
    MediaFamilyType,
    MediaFleetItemDto,
    Responsibility
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {debounceTime, delayWhen, filter, finalize, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {CollaboratorForm, Mode} from '../collaborator-form/collaborator-form.component';
import {NotificationService} from '../../services/utils/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../services/utils/alert.service';
import {FrontEndService} from '../../services/frontEnd.service';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';

@Component({
    selector: 'collaborator-list-form',
    templateUrl: './collaborator-list-form.component.html',
    styleUrls: ['./collaborator-list-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CollaboratorListFormComponent),
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => CollaboratorListFormComponent),
            multi: true
        }
    ]
})
export class CollaboratorListFormComponent implements OnInit, ControlValueAccessor, AsyncValidator {
    @Input() public nbrTotalCollaborators: number;

    @Input() public searching: boolean;

    @Input() public isRefundManager = false;
    @Input() public hasCollaboratorsWriteRole: boolean;
    @Input() public hasSubscriberRolesReadRole: boolean;

    @Input() public searchLimit: number = 100;
    @Input() public pageNumber: number;
    @Input() public nbrPageMax: number;
    private connectedUser: FleetUser;

    @Output() searchChange: EventEmitter<CollaboratorSearchCriteria> = new EventEmitter();
    @Output() refreshCollaborator: EventEmitter<any> = new EventEmitter();
    @Output() navigateToPermissionTabByName: EventEmitter<string> = new EventEmitter();
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    Responsibility = Responsibility;
    foAccountRef: string;
    form: UntypedFormGroup;
    mediasObs: Observable<MediaFleetItemDto[]>;
    collaboratorsFormName = 'collaborators';
    fleetConfig: FrontEndFleetConfig;
    nbOfResult: number = 0;
    addingUser: boolean;
    deletingUser: boolean;
    private initialCollaboratorWithSupervisedStationCount: number = 0;
    collaboratorWithSupervisedStationCount: number = 0; // Value can be updated locally
    supervisedStationCount: number = 0;
    responsibilities: Responsibility[] = Object.values(Responsibility);

    onChangeCallback = (accounts: Array<CollaboratorForm>) => {
    };

    onTouchedCallback = () => {
    };

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly personHttpService: FrontPersonHttpService,
        private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly mediaHttpService: FrontMediaHttpService,
        private readonly notificationService: NotificationService,
        private readonly frontEndService: FrontEndService,
        private readonly translateService: TranslateService,
        private readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        private readonly accountHttpService: FrontAccountHttpService,
        private readonly currentUserContextService: CurrentUserContextService) {
        route.data.pipe(
            tap(data => this.connectedUser = data.user.user),
            tap(data => this.foAccountRef = currentUserContextService.getCurrentFoAccountId())
        ).subscribe(() => {
            this.setMediaObs();
        });
    }

    ngOnInit() {
        this.form = this.fb.group({
            firstName: [''],
            lastName: [''],
            email: [''],
            withPass: [''],
            role: [''],
            collaborators: this.fb.array([], c => this.uniqueMailOnCollaborator(c))
        });

        this.form.get(this.collaboratorsFormName).valueChanges.subscribe(value => {
            this.onChangeCallback(value);
        });

        this.frontEndService.currentFrontEndInfo$
            .subscribe(frontEndInfo => {
                this.fleetConfig = frontEndInfo.fleetConfig;
                if (this.fleetConfig.managerCreationOnly) {
                    this.responsibilities = [Responsibility.MANAGER];
                }
            });

        if (this.isRefundManager) {
            this.foAccountHttpService.findFOAccount()
                .subscribe(foAccount => this.supervisedStationCount = foAccount.activeStationCount);
            this.foAccountHttpService.countCollaboratorsWithSupervisedStation(this.foAccountRef)
                .subscribe(collaboratorsWithSupervisedStation => {
                    this.initialCollaboratorWithSupervisedStationCount = collaboratorsWithSupervisedStation;
                    this.collaboratorWithSupervisedStationCount = collaboratorsWithSupervisedStation;
                });
        }

        this.addItemForm();
    }

    multiAdminEnabled(): boolean {
        return this.fleetConfig && this.fleetConfig.multiAdminEnabled;
    }

    deleteCollaborator(index: number, responsibility: Responsibility) {
        this.alertService.confirm(
            this.computeAlertMessage(responsibility),
            this.computeAlertTitle(responsibility),
            this.translateService.instant('config.collaborator.delete.yes'),
            this.translateService.instant('config.collaborator.delete.no')).subscribe(
            confirm => {
                if (!!confirm) {
                    if (index <= this.collaboratorsForm.length) {
                        const controlToDelete = this.collaboratorsForm.at(index).value;

                        this.deletingUser = true;
                        this.foAccountHttpService.removeUserToFleetRequest(this.foAccountRef, {
                            personId: controlToDelete._id
                        })
                            .pipe(
                                finalize(() => this.deletingUser = false)
                            )
                            .subscribe(() => {
                                this.displayDeletionMessage(responsibility);
                                this.searchCollaborators();
                            }, (error) => {
                                if (error.error && error.error.labelKey) {
                                    this.notificationService.error(error.error.labelKey);
                                } else {
                                    this.displayDeletionErrorMessage(responsibility);
                                }
                            });
                    }
                }
            });
    }

    private displayDeletionErrorMessage(responsibility) {
        if (responsibility === Responsibility.MANAGER) {
            this.notificationService.error('collaborator.deleteManager.error');
        } else {
            this.notificationService.error('collaborator.delete.error');
        }
    }

    private displayDeletionMessage(responsibility) {
        if (responsibility === Responsibility.MANAGER) {
            this.notificationService.success('collaborator.deleteManager.success');
        } else {
            this.notificationService.success('collaborator.delete.success');
        }
    }

    private computeAlertTitle(responsibility) {
        if (responsibility === Responsibility.MANAGER) {
            return this.translateService.instant('config.collaborator.deleteManager.title');
        }
        return this.translateService.instant('config.collaborator.delete.title');
    }

    private computeAlertMessage(responsibility: Responsibility) {
        if (responsibility === Responsibility.MANAGER) {
            return this.translateService.instant('config.collaborator.deleteManager.message');
        }
        return this.translateService.instant('config.collaborator.delete.message');
    }

    addItemForm(control?: UntypedFormControl) {
        if (control) {
            this.collaboratorsForm.push(control);
        } else {
            this.collaboratorsForm.insert(0, this.fb.control({mode: Mode.CREATE}));
        }
        this.ref.detectChanges();
        this.form.updateValueAndValidity();
    }

    sortForm() {
        this.collaboratorsForm.controls = this.collaboratorsForm.controls
            .sort((collaborator1, collaborator2) =>
                this.sortCollaborators(collaborator1.value, collaborator2.value));
    }

    deleteItemForm(index: number) {
        if (index <= this.collaboratorsForm.length) {
            this.collaboratorsForm.removeAt(index);
        }

        if (!this.collaboratorsForm.length) {
            this.ref.detectChanges();
            this.addItemForm();
        }
    }


    get canAddCollaborator(): boolean {
        return this.form.valid &&
            !this.collaboratorsForm.value.filter(collaborator => collaborator && collaborator.mode === Mode.CREATE).length;
    }

    public validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        if (this.form.pending) {
            return this.form.statusChanges.pipe(
                filter(value => value !== 'PENDING'),
                take(1),
                map(value => value === 'VALID' ? null : {'account-list': true})
            );
        } else {
            return of(this.form.valid ? null : {'account-list': true});
        }
    }

    private collaboratorUpdateControl(control: AbstractControl) {
        let savedUser = null;
        let originalPersonId = null;

        control.valueChanges
            .pipe(
                debounceTime(500),
                filter(collaborator => control.valid),
                map(collaborator => collaborator as CollaboratorForm),
                switchMap(collaborator => {
                    if (this.fleetConfig && this.fleetConfig.multiAdminEnabled) {
                        return this.foAccountHttpService.changeResponsibility(
                            this.foAccountRef,
                            collaborator._id,
                            {responsibility: collaborator.responsibility}
                        ).pipe(
                            // The id of the person can change server side.
                            // So we need to rebuild a coherent collaboratorForm for next steps
                            map(user => {
                                savedUser = user;
                                originalPersonId = collaborator._id;

                                collaborator._id = user._id;
                                return collaborator;
                            })
                        );
                    }

                    return of(collaborator);
                }),
                delayWhen(collaborator => {
                    return this.personHttpService.updateCollaboratorWithSupervisedStation({
                        '@type': 'std',
                        _id: collaborator._id,
                        withSupervisedStation: collaborator.withSupervisedStation
                    }).pipe(
                        map(_ => collaborator)
                    );
                }),
                delayWhen(() => {
                    return this.foAccountHttpService.countCollaboratorsWithSupervisedStation(this.foAccountRef)
                        .pipe(
                            map(collaboratorsWithSupervisedStation => {
                                this.initialCollaboratorWithSupervisedStationCount = collaboratorsWithSupervisedStation;
                                this.collaboratorWithSupervisedStationCount = collaboratorsWithSupervisedStation;
                            })
                        );
                }),
                switchMap(collaborator => {
                    return this.personHttpService.updateCollaboratorNames(
                        collaborator._id,
                        collaborator.name,
                        collaborator.lastName
                    ).pipe(
                        map(_ => collaborator)
                    );
                })
            )
            .subscribe((collaborator) => {
                // If person changed, we need to refresh the formControl and fetch medias
                if (originalPersonId !== savedUser._id) {
                    this.refreshCollaborator.emit({});
                    this.setMediaObs();
                }

                this.notificationService.success('collaborator.update.success');
            },
            () => this.notificationService.error('collaborator.error'));
    }

    private setMediaObs() {
        this.mediasObs = this.mediaHttpService.findFleetMediasOptimized(this.foAccountRef,
            false,
            {
                mediaFamilyTypes: [MediaFamilyType.DEFAULT]
            }
        ).pipe(shareReplay(1));
    }

    public writeValue(collaboratorForms: Array<CollaboratorForm>): void {
        if (this.collaboratorsForm.length) {
            while (this.collaboratorsForm.length) {
                this.collaboratorsForm.removeAt(0);
            }
            this.nbOfResult = 0;
        }
        this.ref.detectChanges();

        if (collaboratorForms && collaboratorForms.length) {
            collaboratorForms
                .sort((collaborator1, collaborator2) => this.sortCollaborators(collaborator1, collaborator2))
                .forEach(collaboratorForm => {
                    const formControl = this.fb.control(collaboratorForm);
                    if (collaboratorForm.mode === Mode.UPDATE) {
                        this.collaboratorUpdateControl(formControl);
                    }
                    this.addItemForm(formControl);
                });
            this.nbOfResult = collaboratorForms.length;
        } else {
            this.addItemForm();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    get collaboratorsForm(): UntypedFormArray {
        return this.form.controls[this.collaboratorsFormName] as UntypedFormArray;
    }

    public searchCollaborators() {
        this.collaboratorWithSupervisedStationCount = this.initialCollaboratorWithSupervisedStationCount;

        let searchCriteria = {
            lastName: this.form.get('lastName').value,
            firstName: this.form.get('firstName').value,
            email: this.form.get('email').value,
            withPass: this.form.get('withPass').value,
            role: this.form.get('role').value
        };
        this.searchChange.emit(searchCriteria);
    }

    private uniqueMailOnCollaborator(c: AbstractControl): ValidationErrors {
        const formArray = c as UntypedFormArray;
        const mails = (formArray.value as Array<CollaboratorForm>)
            .filter(collaborator => collaborator && collaborator.mode === Mode.CREATE)
            .map(collaborator => collaborator.email);

        if (new Set(mails).size !== mails.length) {
            return {mailCollaborators: true};
        }
        return null;
    }

    private sortCollaborators(collaborator1: CollaboratorForm, collaborator2: CollaboratorForm): number {
        let compareResult: number = 0;
        // form in create mode are always in first
        if (collaborator1.mode !== collaborator2.mode) {
            if (collaborator1.mode === Mode.CREATE) {
                compareResult = -1;
            } else {
                compareResult = 1;
            }
        } else {
            compareResult = collaborator1.lastName.localeCompare(collaborator2.lastName);

            if (compareResult === 0) {
                compareResult = collaborator1.name.localeCompare(collaborator2.name);
            }
        }

        return compareResult;
    }

    // Import collaborators

    createCollaboratorImportRequestReportObs: (string, number) => Observable<string> =
        (fileId, maxErrorToReport) => this.personHttpService.createCollaboratorImportRequestReport(this.foAccountRef, fileId, maxErrorToReport);

    fetchImportRequestReport: (string) => Observable<FleetImportRequestReportProcessing> =
        (importId) => this.personHttpService.fetchImportRequestReportObs(this.foAccountRef, importId);
    importRequestObs: (string) => Observable<void> =
        collaboratorImportRequestId => this.personHttpService.importCollaborators(this.foAccountRef, collaboratorImportRequestId);

    addUserToFleetRequest(index: number, confirm: boolean = false) {
        if (index <= this.collaboratorsForm.length) {
            const controlToAdd: UntypedFormControl = this.collaboratorsForm.at(index) as UntypedFormControl;

            if (controlToAdd && controlToAdd.valid && controlToAdd.value && controlToAdd.value.mode === Mode.CREATE) {
                const user = this.computeUser(controlToAdd.value, confirm);

                this.addingUser = true;
                this.foAccountHttpService.addUserToFleetRequest(this.foAccountRef, user)
                    .pipe(
                        finalize(() => this.addingUser = false)
                    ).subscribe((subscriber) => {
                        if (user.mediaId) {
                            this.mediaHttpService.attributeMediaToSubscriber(
                                this.foAccountRef,
                                subscriber.personRef,
                                user.mediaId,
                                subscriber._id,
                                []
                            ).subscribe(() => {
                                this.notificationService.success('collaborator.create.success');
                                this.searchCollaborators();
                                this.setMediaObs();
                            }, () => {
                                this.openErrorMessage('config.collaborator.media.attribution');
                            });
                        } else {
                            this.searchCollaborators();
                            this.setMediaObs();
                        }
                    },
                    (error) => {
                        switch (error.status) {
                            case 409:
                                this.openConfirmationPopup(index);
                                break;
                            case 422:
                            case 400:
                                if (error.error.labelKey) {
                                    this.openErrorMessage(error.error.labelKey);
                                } else {
                                    this.openErrorMessage('config.collaborator.email.referentStdUser');
                                }
                                break;
                            default:
                                break;
                        }
                    });
            }
        }
    }

    private computeUser(user: any, confirm: boolean): FleetUserRequest {
        return {
            confirm: confirm,
            email: user.email,
            firstName: user.name,
            lastName: user.lastName,
            mediaId: user.media.id,
            responsibility: user.responsibility,
        };
    }

    private openConfirmationPopup(index: number) {
        this.alertService.confirm(
            this.translateService.instant('config.collaborator.popup.message'),
            null,
            this.translateService.instant('config.collaborator.popup.buttonYes'),
            this.translateService.instant('config.collaborator.popup.buttonNo')
        ).subscribe((accept) => {
            if (accept) {
                this.addUserToFleetRequest(index, true);
            }
        });
    }

    private openErrorMessage(key: string) {
        this.notificationService.error(key);
    }

    withSupervisedStationChanged(count: number) {
        this.collaboratorWithSupervisedStationCount = count;
    }

    public redirectAndFilterOnPermissionTab(manager: AbstractControl): void {
        this.navigateToPermissionTabByName.emit(manager.value.name + ' ' + manager.value.lastName);
    }

    public getQueryParamsFromCriteria(): string {
        let queryParams = '';
        if (!!this.form.get('firstName').value ||
            !!this.form.get('lastName').value ||
            !!this.form.get('email').value ||
            !!this.form.get('withPass').value ||
            !!this.form.get('role').value) {
            queryParams += '?';
        } else {
            return queryParams;
        }
        if (!!this.form.get('firstName').value) {
            queryParams += 'firstName=' + this.form.get('firstName').value + '&';
        }
        if (!!this.form.get('lastName').value) {
            queryParams += 'lastName=' + this.form.get('lastName').value + '&';
        }
        if (!!this.form.get('email').value) {
            queryParams += 'email=' + this.form.get('email').value + '&';
        }
        if (!!this.form.get('withPass').value) {
            queryParams += 'withPass=' + this.form.get('withPass').value + '&';
        }
        if (!!this.form.get('role').value) {
            queryParams += 'role=' + this.form.get('role').value;
        }
        return queryParams;
    }
}
